import { Logo } from '@finn/design-system/icons/logo';
import { Image } from '@finn/ui-components';
import { getCloudinaryImgUrl, useIsMobile } from '@finn/ui-utils';
import { makeStyles, Theme } from '@material-ui/core';
import cn from 'classnames';
import React from 'react';

import {
  IComparisonTableHeader,
  ImageObjectData,
} from '~/types/comparisonTable';

import Price from '../../Price';

const useStyles = makeStyles((theme: Theme) => ({
  row: {
    height: '90px',
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '28px',
  },
  cell: {
    padding: theme.spacing(3, 0),
    verticalAlign: 'bottom',
  },
  logo: {
    height: '26px',
    width: '75px',
    marginBottom: theme.spacing(-0.5),
  },
  logoStyling: {
    width: '48px',
  },
  columnTitle: {
    minWidth: '88px',
    fontWeight: 600,
  },
  titleStyling: {
    fontSize: '14px',
    verticalAlign: 'middle',
    [theme.breakpoints.up('sm')]: {
      fontSize: '16px',
    },
  },
  columnTitleVariant: {
    marginTop: theme.spacing(1),
  },
  nonFeatured: {
    marginBottom: theme.spacing(0.5),
  },
  titleSection: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5),
    textAlign: 'left',
    [theme.breakpoints.up('sm')]: {
      marginRight: theme.spacing(1.5),
    },
    [theme.breakpoints.up(800)]: {
      marginBottom: theme.spacing(0),
    },
  },
  titleText: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1.5),
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
  image: {
    height: '75px',
  },
}));

export interface IComparisonTableHeaderItem extends IComparisonTableHeader {
  featured: boolean;
}
interface IProps {
  header: {
    title: string;
    subtitle?: string;
  };
  items: IComparisonTableHeaderItem[];
  alternativeVariant?: boolean;
  image?: ImageObjectData;
}
const TableHeader: React.FunctionComponent<IProps> = ({
  header,
  items,
  alternativeVariant = false,
  image = {} as ImageObjectData,
}) => {
  const classes = useStyles();
  const isMobile = useIsMobile();

  const { title = '', subtitle = '' } = header;

  const TitleSection = () => (
    <div className={classes.titleSection}>
      <div className={classes.titleText}>
        <span className="body-14-light">{subtitle}</span>
        <span className="global-t5-semibold">{title}</span>
      </div>
      <div>
        <Image
          src={getCloudinaryImgUrl(image?.image?.url)}
          alt={image?.alt_text}
          className={classes.image}
        />
      </div>
    </div>
  );

  return (
    <thead>
      {isMobile && (
        <tr className={classes.row}>
          <td colSpan={3}>
            <TitleSection />
          </td>
        </tr>
      )}
      <tr className={classes.row}>
        <th>{alternativeVariant ? !isMobile && <TitleSection /> : title}</th>
        {items.map((item, idx) => (
          <th
            key={idx}
            className={cn(classes.cell, {
              'rounded-tl-sm rounded-tr-sm bg-black text-white': item.featured,
              [classes.titleStyling]: alternativeVariant,
            })}
          >
            {item.price ? (
              <Price price={item.price} />
            ) : (
              <div
                className={cn(classes.columnTitle, {
                  [classes.columnTitleVariant]: alternativeVariant,
                })}
              >
                {item.featured ? (
                  <Logo
                    className={cn(classes.logo, 'fill-white', {
                      [classes.logoStyling]: alternativeVariant,
                    })}
                  />
                ) : (
                  <div
                    className={cn({
                      [classes.nonFeatured]: alternativeVariant,
                    })}
                  >
                    {item.title}
                  </div>
                )}
              </div>
            )}
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default TableHeader;
