import { InfoOutlined } from '@finn/design-system/icons/info-outlined';
import { InfoToolTip } from '@finn/ui-components';
import { Box, makeStyles, Typography } from '@material-ui/core';
import cn from 'classnames';
import React from 'react';

import {
  IComparisonTableItemBaseValue,
  IComparisonTableMetadataDimension,
} from '~/types/comparisonTable';

import TableCellData from './TableCellData';

const useStyles = makeStyles((theme) => ({
  row: {
    height: '72px',
    '&:last-of-type $cell': {
      borderBottomWidth: '1px',
    },
  },
  rowVariant: {
    '&:last-of-type $cell': {
      borderBottom: 'none',
    },
    '&:last-of-type $valueCell': {
      fontSize: '24px',
    },
  },
  cell: {
    border: '1px solid',
    borderBottomWidth: 0,
    borderRightWidth: 0,
    fontWeight: 600,
    '$featured + &': {
      borderLeft: 0,
    },
    '&:first-of-type': {
      borderLeftWidth: 0,
    },
    '&:last-of-type': {
      borderRightWidth: 0,
    },
  },
  dimensionCell: {
    width: '680px',
    padding: theme.spacing(0, 3, 0, 0),
    height: 72,
  },
  valueCell: {
    width: '190px',
    textAlign: 'center',
  },
  featured: {
    borderLeft: 0,
    borderRight: 0,
  },
  dimensionWithInfoWrapper: {
    display: 'flex',
    alignItems: 'center',
    width: 'max-content',
    '& svg': {
      width: 20,
    },
  },
  TooltipWrapper: {
    cursor: 'pointer',
  },
}));

export interface IComparisonTableBodyItem {
  featured: boolean;
  values: Record<string, IComparisonTableItemBaseValue>;
}
interface IProps {
  dimensions: IComparisonTableMetadataDimension[];
  items: IComparisonTableBodyItem[];
}
const TableBody: React.FunctionComponent<IProps> = ({ dimensions, items }) => {
  const classes = useStyles();

  return (
    <tbody>
      {dimensions.map((dimension, idx) => (
        <tr key={idx}>
          <Typography
            variant="h5"
            component="td"
            className={cn(classes.cell, classes.dimensionCell, '!border-pearl')}
          >
            {dimension.description && (
              <div className={classes.dimensionWithInfoWrapper}>
                <Box mr={1}>{dimension.name}</Box>
                <div className={classes.TooltipWrapper}>
                  <InfoToolTip
                    content={dimension.description}
                    Icon={InfoOutlined}
                  />
                </div>
              </div>
            )}
            {!dimension.description && (
              <>
                {dimension.name}
                {dimension?.additionalText && (
                  <span className="body-12-light">
                    {dimension.additionalText}
                  </span>
                )}
              </>
            )}
          </Typography>
          {items.map((item, itemIdx) => (
            <td
              key={itemIdx}
              className={cn(classes.cell, classes.valueCell, {
                [cn(classes.featured, '!border-black bg-black text-white')]:
                  item.featured,
                '!border-pearl': !item.featured,
              })}
            >
              {item.values[dimension.id] && (
                <TableCellData
                  featured={item.featured}
                  data={item.values[dimension.id]}
                />
              )}
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  );
};

export default TableBody;
