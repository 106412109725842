import { cn } from '@finn/ui-utils';
import { makeStyles, Theme } from '@material-ui/core';
import React from 'react';

import { IComparisonTableFooterLinkAction } from '~/types/comparisonTable';
import Button from '~/ui-elements/Button';

type Props = {
  fullWidth?: boolean;
};

const useStyles = makeStyles<Theme, Props>(() => ({
  button: {
    width: ({ fullWidth }) => (fullWidth ? '100%' : ''),
  },
}));

interface IProps {
  action: IComparisonTableFooterLinkAction;
  fullWidth?: boolean;
}
const LinkFooterAction: React.FunctionComponent<IProps> = ({
  action,
  fullWidth,
}) => {
  const classes = useStyles({ fullWidth });

  return (
    <Button
      variant="outlined"
      label={action.title}
      href={action.href}
      className={cn(
        classes.button,
        'border-white text-white hover:bg-black hover:text-black'
      )}
    />
  );
};

export default LinkFooterAction;
