import { ExpandMore } from '@finn/design-system/icons/expand-more';
import { Logo } from '@finn/design-system/icons/logo';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  List,
  ListItem,
  makeStyles,
  Typography,
} from '@material-ui/core';
import cn from 'classnames';
import get from 'lodash/get';
import React from 'react';

import {
  IComparisonTable,
  IComparisonTableMetadata,
  IComparisonTableMetadataDimension,
} from '~/types/comparisonTable';

import Action from '../Action';
import TableCellData from '../ComparisonTable/Body/TableCellData';
import Price from '../Price';

const useStyles = makeStyles((theme) => ({
  arrowIcon: {
    width: '24px',
    height: '24px',
  },
  dimensionLabel: {
    marginLeft: theme.spacing(2.5),
  },
  accordionRoot: {
    margin: `${theme.spacing(0)} !important`,
  },
  accordionSummaryRoot: {
    padding: theme.spacing(0, 1),
  },
  accordionSummaryContent: {
    margin: theme.spacing(2.5, 0),
    flexDirection: 'column',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  continue: {
    height: '48px',
    fontWeight: 600,
    margin: theme.spacing(3, 0, 1),
  },
  price: {
    display: 'flex',
    alignItems: 'baseline',
    marginTop: theme.spacing(1),
  },
  featured: {},
  listItem: {
    padding: theme.spacing(1.5, 0),
  },
  actionWrapper: {
    marginTop: theme.spacing(2),
  },
}));

interface IProps {
  data: IComparisonTable;
}

const ComparisonAccordion: React.FunctionComponent<IProps> = ({ data }) => {
  const classes = useStyles();

  const { metadata = {} as IComparisonTableMetadata, items = [] } = data;

  return (
    <>
      {items.map((item, idx) => (
        <Accordion
          key={idx}
          className={cn({ [classes.featured]: item.featured })}
          classes={{
            root: cn(classes.accordionRoot, {
              '!bg-black !text-white': item.featured,
            }),
          }}
          defaultExpanded={item.featured}
          elevation={0}
          square
        >
          <AccordionSummary
            classes={{
              root: classes.accordionSummaryRoot,
              content: classes.accordionSummaryContent,
            }}
            expandIcon={
              <ExpandMore
                className={cn('min-h-11 min-w-11', {
                  'fill-black': !item.featured,
                  'fill-white': item.featured,
                })}
              />
            }
          >
            {item.featured ? (
              <>
                {item.header.price ? (
                  <Price price={item.header.price} className={classes.price} />
                ) : (
                  <Logo className="fill-white" />
                )}
              </>
            ) : (
              <Typography variant="h5" component="span">
                {item.header.title}
              </Typography>
            )}
          </AccordionSummary>
          <AccordionDetails className={classes.details}>
            <List disablePadding>
              {metadata.dimensions.map(
                (
                  dimension: IComparisonTableMetadataDimension,
                  dIdx: number
                ) => (
                  <ListItem
                    key={dIdx}
                    disableGutters
                    className={classes.listItem}
                  >
                    <TableCellData
                      featured={item.featured}
                      data={get(item, `values.${get(dimension, 'id', '')}`, {})}
                    />
                    <Typography
                      variant="h6"
                      component="span"
                      className={cn(classes.dimensionLabel, {
                        'text-black': !item.featured,
                        'text-white': item.featured,
                      })}
                    >
                      {dimension.name}
                    </Typography>
                  </ListItem>
                )
              )}
            </List>
            {item.footer?.price && (
              <Price price={item.footer.price} className={classes.price} />
            )}
            {item.footer?.action && (
              <div className={classes.actionWrapper}>
                <Action action={item.footer.action} fullWidth />
              </div>
            )}
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
};

export default ComparisonAccordion;
