import { cn, useFormattedPrice } from '@finn/ui-utils';
import { makeStyles, Typography } from '@material-ui/core';
import React from 'react';

import { IComparisonTableHeaderPrice } from '~/types/comparisonTable';

const useStyles = makeStyles((theme) => ({
  description: {
    ...theme.typography.c2,
    marginLeft: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      marginLeft: 0,
    },
  },
}));

interface IProps {
  price: IComparisonTableHeaderPrice;
  className?: string;
}

const Price: React.FunctionComponent<IProps> = ({ price, className }) => {
  const classes = useStyles();
  const priceValue = useFormattedPrice(price.value, 0);

  return (
    <div className={className}>
      <Typography variant="h4" component="span">
        {priceValue}
      </Typography>
      <Typography className={cn(classes.description, 'text-white')}>
        {price.description}
      </Typography>
    </div>
  );
};

export default Price;
