import { Container } from '@finn/atoms';
import { useIsMobileAndTablet } from '@finn/ui-utils';
import { makeStyles, Typography } from '@material-ui/core';
import React from 'react';

import { IComparisonTable } from '~/types/comparisonTable';
import { ComparisonCosmicMetadata } from '~/types/UIModules';
import { parseToHtml } from '~/utils/html';

import ComparisonAccordion from './ComparisonAccordian/ComparisonAccordian';
import ComparisonTable from './ComparisonTable/ComparisonTable';

const useStyles = makeStyles((theme) => ({
  title: {
    width: '960px',
    maxWidth: '100%',
    marginBottom: theme.spacing(3),
  },
  description: {
    width: '960px',
    maxWidth: '100%',
    marginBottom: theme.spacing(5),
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(10),
    },
  },
}));

interface IProps {
  data: ComparisonCosmicMetadata;
}

const BaseComparisonSection: React.FunctionComponent<IProps> = ({ data }) => {
  const classes = useStyles();
  const {
    title,
    description,
    table = {} as IComparisonTable,
    header_image: tableImage,
  } = data;

  const isMobile = useIsMobileAndTablet();

  return (
    <Container>
      {title !== '' && (
        <Typography className={classes.title} variant="h2">
          {title}
        </Typography>
      )}

      {description !== '' && (
        <Typography className={classes.description} component="div">
          {parseToHtml(description)}
        </Typography>
      )}

      {!isMobile && <ComparisonTable data={table} image={tableImage} />}

      {isMobile && <ComparisonAccordion data={table} />}
    </Container>
  );
};

export default BaseComparisonSection;
