import throttle from 'lodash/throttle';
import { useEffect, useState } from 'react';

export const useViewportHeight = () => {
  const [vh, setVh] = useState<number>();

  useEffect(() => {
    // check if window is defined (so if in the browser or in node.js)
    if (typeof window === 'undefined') return;

    function handleResize() {
      setVh(window.innerHeight * 0.01);
    }

    window.addEventListener('resize', throttle(handleResize, 500), {
      passive: true,
    });

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return vh;
};
